module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HXOUSE - Privacy Policy","short_name":"HXOUSE - Privacy Policy","description":"HXOUSE is a Toronto-based, globally focused think-center. Serving its community as an incubator and accelerator, it helps to foster innovation and opportunity for creative entrepreneurs.","lang":"en","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"src/images/favicon-hd.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0350602f0c9f308dd842c10b162a9f25"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
